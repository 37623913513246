import React, { useState } from 'react'
import { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './css/style.css';

function App() {
  const [ data, setData] = useState([])
  const [ pais ] = useState('CL')
  const [ folio, setFolio ] = useState('')
  const [ fechaEmision, setFechaEmision ] = useState('')
  const [ monto, setMonto ] = useState('')
  const [ captchaValido, setCaptchaValido ] = useState(null)
  const [ montoValido, setMontoValido ] = useState(false)
  const [ folioValido, setFolioValido ] = useState(false)
  const [ fechaEmisionValida, setFechaEmisionValida ] = useState(false)
  const  [ boletaValida, setBoletaValida ] = useState(false)
  const  [ boletaFechaValida, setBoletaFechaValida ] = useState(false)
  const  [ boletaMontoValido, setBoletaMontoValido ] = useState(false)
  const [ btnDescarga, setBtnDescarga ] = useState(false)
  const captcha = useRef(null)

  const onChange = () => {
    if (captcha.current.getValue()) {
      setCaptchaValido(false)
    }     
  }

  const filterFolios = async (pais, folio, fechaEmision, monto ) => {  
    const data = await fetch('https://www.extranetrosen.cl/AzureRestApi/api/Documents/?Pais='+pais+ '&Folio='+folio+'&FechaEmision='+fechaEmision+ '&Monto='+monto, {
    method: 'GET',
    headers: { 
            'Rosen-API-Key': '{C773D27E-97AA-41FD-AC91-FEFD291A171E}',
            'Rosen-API-Secret': '56EB87EB-A595-40F5-97EA-2D523CF26663-99D5571B-D0FB-4682-A3F6-5B42DCE202F1'
          }
    })

    const datos = await data.json()
    console.log(data)
    if(datos.Urls)  {
 
        var datParse = fechaEmision.split('-')[2] + '-' + fechaEmision.split('-')[1] + '-' + fechaEmision.split('-')[0];
        console.log(datos.FechaEmision);
        console.log(fechaEmision.toString());
        console.log(datParse);
      
      if(datos.FechaEmision !== datParse)
      {
          setBoletaFechaValida(true)
          setBtnDescarga(false)
          setBoletaMontoValido(false)
          setBoletaValida(false)
          return;
      }

      if(datos.Monto !==  monto)
      {
          setBoletaMontoValido(true)
          setBoletaFechaValida(false)
          setBtnDescarga(false)
          setBoletaValida(false)
          return;
      }

      setData(datos.Urls)
      setBoletaValida(false)
      setBoletaFechaValida(false)
      setBoletaMontoValido(false)
      setBtnDescarga(true)


    } else {
            setBoletaValida(true) 
            setBtnDescarga(false)
            setBoletaFechaValida(false)
            setBoletaMontoValido(false)
          }      
  } 

const handleOnMonto = (event)=>{

  const result = event.target.value.replace(/\D/g, '');

  setMonto(result)
 
  if (result)   setMontoValido(false)
  else setMontoValido(true)
}
 
const handleOnState = (event)=>{
  setFolio(event.target.value)

  if (event.target.value)   setFolioValido(false)
  else setFolioValido(true)
}
 
const handleOnDocument = (event)=>{
  setFechaEmision(event.target.value)

  if (event.target.value)   setFechaEmisionValida(false)
  else setFechaEmisionValida(true)
}

const saveFilter = () => {

  if (!captcha.current.getValue())
  {
    setCaptchaValido(true)
  }

  if (!folio)
  {
    setFolioValido(true)
  }

  if (!monto)
  {
    setMontoValido(true)
  }

  if (!fechaEmision)
  {
    setFechaEmisionValida(true)
  }

  if (captcha.current.getValue() && folio && monto && fechaEmision) {
    filterFolios(pais, folio, fechaEmision, monto)
  } 

}
const downloadDoc = (event)=>{
  event.preventDefault();
  window.open(event.target.getAttribute('url'))
}


return (

  <div class="container-fullscreen">
  <div class="img-mob"></div>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="content-side">
        <div className="">
          <h1 className="title-ppal mb-3">Consulta de Documento Electrónico</h1>
          <div className="form-group float-label-control">
            <p className="title-boleta">Número de boleta</p>  
            <input
              className='desc-rut'
              type="text"  
              name='folio'
              value={folio} onChange={handleOnState}
              aria-describedby="emailHelp" 
              placeholder="Ejemplo: 123456"/>
          </div>
          {folioValido === true && <div className="col-lg-6 mage-error mt-2 mb-2">Por favor ingresa el Número de boleta</div>  }
          <div className="form-group float-label-control">
            <p className="title-boleta">Fecha de emisión</p>
            <input 
              type="date"
              name='fechaEmision'
              value={fechaEmision} onChange={handleOnDocument}
              className="ingresa-boleta" 
              placeholder="Ejemplo: 09/11/2024"/>
          </div>
          {fechaEmisionValida === true && <div className="col-lg-6 mage-error mt-2 mb-2">Por favor ingresa la fecha emisión</div>  }
          <div className="form-group float-label-control">
            <p className="title-boleta">Monto total</p>  
            <input
            className='desc-monto'
            type="text"
            name='monto'
            value={monto} onChange={handleOnMonto}
            placeholder="Ejemplo: 19990"
            />
          </div>
          {montoValido === true && <div className="col-lg-6 mage-error mt-2 mb-2">Por favor ingresa el monto</div>  }
          <div className="form-group float-label-control">
            <ReCAPTCHA
            ref={captcha}
               sitekey="6Lc4IAAdAAAAANb_QEk_Y77mTCfOs8fH9TH6BC0e"
          /*sitekey="6LcBuJccAAAAAFvK7pStH7KTxaAaDFsJPQ2HE3iF" */
            onChange={onChange}
            />
          </div>      
          {captchaValido === true && <div className="col-lg-6 mage-error mt-2 mb-2">Por favor acepta el captcha</div>  }
        <button onClick={saveFilter} className="btn btn-md btn-color" type="submit">Buscar</button>
            
        {btnDescarga === true &&

            data.map((item, index) =>
              <button  className="btn btn-sm btn-color m-4" key={index} onClick={downloadDoc} url={item.UrlOriginal}> Descargar Documento</button>
            )
        } 

        {boletaValida === true && <div className="col-lg-6 alert-danger mt-2">No se encontro Boleta</div>  }
        {boletaFechaValida === true && <div className="col-lg-6 alert-danger mt-2">La fecha de la Boleta no corresponde</div>  }
        {boletaMontoValido === true && <div className="col-lg-6 alert-danger mt-2">El monto de la Boleta no corresponde</div>  }

        
       </div>
      </div>      
    </div>
  );
}

export default App;